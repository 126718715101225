import React from "react";
import {
  Box,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Select,
  Grid,
  MenuItem,
  Stack,
} from "@mui/material";
import { ActivityLevel, ActivityLevelLabel } from "../../../../data/Types";
import BlueButton from "../../../../components/BlueButton";
import moment from "moment";
import { LanguageCodes } from "../../../../data/Types";
import { DaysOfWeek, DaysOfWeekString } from "../../../../data/GlobalData";

const ClientEditDialog = ({ client, onDialogClosed }) => {
  const [error, setError] = React.useState(null);

  const [fieldErrors, setFieldErrors] = React.useState({
    firstName: false,
    lastName: false,
    email: false,
  });

  const [firstName, setFirstName] = React.useState(
    client ? client.firstName : "",
  );
  const [lastName, setLastName] = React.useState(client ? client.lastName : "");
  const [email, setEmail] = React.useState(
    client && client.email ? client.email : "",
  );
  const [checkInDay, setCheckInDay] = React.useState(
    client && client.checkInDay ? client.checkInDay : "saturday",
  );

  const [height, setHeight] = React.useState(
    client ? client.statistics.height : "",
  );
  const [goal, setGoal] = React.useState(client ? client.statistics.goal : "");
  const [weight, setWeight] = React.useState(
    client ? client.statistics.weight : 0,
  );
  const [startingWeight, setStartingWeight] = React.useState(
    client && client.statistics && client.statistics.startingWeight
      ? client.statistics.startingWeight
      : 0,
  );

  const [sex, setSex] = React.useState(
    client ? client.statistics.sex : "female",
  );

  const [birthDate, setBirthDate] = React.useState(
    client && client.statistics.birthDate
      ? client.statistics.birthDate.toDate()
      : null,
  );

  const [renewDate, setRenewDate] = React.useState(
    client && client.renewDate ? client.renewDate.toDate() : null,
  );

  const [language, setLanguage] = React.useState(
    client && client.language ? client.language : "en",
  );

  const [experience, setExperience] = React.useState(
    client ? client.statistics.experience : "beginner",
  );
  const [activityLevel, setActivityLevel] = React.useState(
    client && client.statistics.activityLevel
      ? client.statistics.activityLevel
      : ActivityLevel.sedentary,
  );

  const [status, setStatus] = React.useState(
    client && client.status ? client.status : "active",
  );
  const [tier, setTier] = React.useState(
    client && client.tier ? client.tier : "tier1",
  );

  function validate() {
    if (firstName.length === 0) {
      setFieldErrors({
        firstName: true,
        lastName: false,
        email: false,
      });
      return "First name is required.";
    }
    if (lastName.length === 0) {
      setFieldErrors({
        firstName: false,
        lastName: true,
        email: false,
      });
      return "Last name is required.";
    }
    // if (email.length === 0) {
    //   setFieldErrors({
    //     firstName: false,
    //     lastName: false,
    //     email: true,
    //   });
    //   return "Email is required.";
    // }
    if (email.length !== 0) {
      // validate the email
      const re = /\S+@\S+\.\S+/;
      if (!re.test(email)) {
        setFieldErrors({
          firstName: false,
          lastName: false,
          email: true,
        });
        return "Invalid email address.";
      }
    }
    return null;
  }

  return (
    <Dialog
      maxWidth="lg"
      open={true}
      onClose={() => {
        if (onDialogClosed) {
          onDialogClosed(null);
        }
      }}
    >
      <DialogTitle>{client ? "Edit Client" : "Add Client"}</DialogTitle>

      <DialogContent>
        <Box style={{ display: "flex", alignItems: "center", marginTop: 18 }}>
          <Stack>
            <Typography style={{ width: 200 }}>First Name:</Typography>
            <Typography style={{ width: 200, fontSize: 12 }}>
              (required)
            </Typography>
          </Stack>
          <TextField
            value={firstName}
            error={fieldErrors.firstName}
            style={{ width: 512 }}
            onChange={(event) => {
              setFirstName(event.target.value);
            }}
          ></TextField>
        </Box>
        <Box style={{ display: "flex", alignItems: "center", marginTop: 18 }}>
          <Stack>
            <Typography style={{ width: 200 }}>Last Name:</Typography>
            <Typography style={{ width: 200, fontSize: 12 }}>
              (required)
            </Typography>
          </Stack>
          <TextField
            value={lastName}
            error={fieldErrors.lastName}
            style={{ width: 512 }}
            variant="outlined"
            onChange={(event) => {
              setLastName(event.target.value);
            }}
          />
        </Box>

        <Box style={{ display: "flex", alignItems: "center", marginTop: 18 }}>
          <Stack>
            <Typography style={{ width: 200 }}>Check In Day:</Typography>
          </Stack>
          <Select
            style={{ width: 512 }}
            variant="outlined"
            value={checkInDay}
            onChange={(event) => {
              setCheckInDay(event.target.value);
            }}
          >
            <MenuItem key={DaysOfWeek.monday} value={DaysOfWeek.monday}>
              {DaysOfWeekString[DaysOfWeek.monday]}
            </MenuItem>
            <MenuItem key={DaysOfWeek.tuesday} value={DaysOfWeek.tuesday}>
              {DaysOfWeekString[DaysOfWeek.tuesday]}
            </MenuItem>
            <MenuItem key={DaysOfWeek.wednesday} value={DaysOfWeek.wednesday}>
              {DaysOfWeekString[DaysOfWeek.wednesday]}
            </MenuItem>
            <MenuItem key={DaysOfWeek.thursday} value={DaysOfWeek.thursday}>
              {DaysOfWeekString[DaysOfWeek.thursday]}
            </MenuItem>
            <MenuItem key={DaysOfWeek.friday} value={DaysOfWeek.friday}>
              {DaysOfWeekString[DaysOfWeek.friday]}
            </MenuItem>
            <MenuItem key={DaysOfWeek.saturday} value={DaysOfWeek.saturday}>
              {DaysOfWeekString[DaysOfWeek.saturday]}
            </MenuItem>
            <MenuItem key={DaysOfWeek.sunday} value={DaysOfWeek.sunday}>
              {DaysOfWeekString[DaysOfWeek.sunday]}
            </MenuItem>
          </Select>
        </Box>

        <Grid container spacing={4}>
          <Grid item>
            <Box
              style={{ display: "flex", alignItems: "center", marginTop: 18 }}
            >
              <Typography style={{ width: 200 }}>Email:</Typography>

              <TextField
                error={fieldErrors.email}
                value={email}
                style={{ width: 220 }}
                variant="outlined"
                onChange={(event) => {
                  setEmail(event.target.value);
                }}
              />
            </Box>
          </Grid>
          <Grid item>
            <Box
              style={{ display: "flex", alignItems: "center", marginTop: 18 }}
            >
              <Typography style={{ width: 95 }}>Renew date:</Typography>
              <TextField
                type="date"
                value={renewDate ? moment(renewDate).format("YYYY-MM-DD") : ""}
                variant="outlined"
                onChange={(event) => {
                  const value = event.target.value;
                  const renewDateValue = moment(value);

                  setRenewDate(renewDateValue.toDate());
                }}
              />
            </Box>
          </Grid>
        </Grid>

        <Grid container spacing={4}>
          <Grid item>
            <Box
              style={{ display: "flex", alignItems: "center", marginTop: 18 }}
            >
              <Typography style={{ width: 200 }}>Height:</Typography>
              <TextField
                value={height}
                error={false}
                style={{ width: 90 }}
                variant="outlined"
                onChange={(event) => {
                  setHeight(event.target.value);
                }}
              />
            </Box>
          </Grid>
          <Grid item>
            <Box
              style={{ display: "flex", alignItems: "center", marginTop: 18 }}
            >
              <Typography style={{ width: 75 }}>Goal Weight:</Typography>
              <TextField
                value={weight}
                style={{ width: 90, marginLeft: 8 }}
                variant="outlined"
                onChange={(event) => {
                  let value = parseFloat(event.target.value);
                  if (isNaN(value)) {
                    value = 0;
                  }
                  setWeight(value);
                }}
              ></TextField>
            </Box>
          </Grid>
          <Grid item>
            <Box
              style={{ display: "flex", alignItems: "center", marginTop: 18 }}
            >
              <Typography style={{ width: 75 }}>Starting Weight:</Typography>
              <TextField
                value={startingWeight ? startingWeight : 0}
                style={{ width: 90, marginLeft: 8 }}
                variant="outlined"
                onChange={(event) => {
                  let value = parseFloat(event.target.value);
                  if (isNaN(value)) {
                    value = 0;
                  }
                  setStartingWeight(value);
                }}
              ></TextField>
            </Box>
          </Grid>
        </Grid>

        <Grid container spacing={4}>
          <Grid item>
            <Box
              style={{ display: "flex", alignItems: "center", marginTop: 18 }}
            >
              <Typography style={{ width: 200 }}>Birth date:</Typography>
              <TextField
                type="date"
                value={birthDate ? moment(birthDate).format("YYYY-MM-DD") : ""}
                variant="outlined"
                onChange={(event) => {
                  const value = event.target.value;
                  const birthDateValue = moment(value);
                  setBirthDate(birthDateValue.toDate());
                }}
              ></TextField>
            </Box>
          </Grid>
          <Grid item>
            <Box
              style={{ display: "flex", alignItems: "center", marginTop: 18 }}
            >
              <Typography style={{ width: 75 }}>Language:</Typography>
              <Select
                style={{ width: 200, marginLeft: 8 }}
                variant="outlined"
                value={language}
                onChange={(event) => {
                  setLanguage(event.target.value);
                }}
              >
                {LanguageCodes.map((language) => {
                  return (
                    <MenuItem key={language.code} value={language.code}>
                      {language.label}
                    </MenuItem>
                  );
                })}
              </Select>
            </Box>
          </Grid>
        </Grid>

        <Box style={{ display: "flex", alignItems: "center", marginTop: 18 }}>
          <Typography style={{ width: 200 }}>Fitness Goals:</Typography>
          <TextField
            value={goal}
            style={{ width: 512 }}
            variant="outlined"
            onChange={(event) => {
              setGoal(event.target.value);
            }}
          ></TextField>
        </Box>

        <Box style={{ display: "flex", alignItems: "center", marginTop: 18 }}>
          <Typography style={{ width: 200 }}>Gender:</Typography>
          <Select
            style={{ width: 512 }}
            variant="outlined"
            value={sex}
            onChange={(event) => {
              setSex(event.target.value);
            }}
          >
            <MenuItem value="male">Male</MenuItem>
            <MenuItem value="female">Female</MenuItem>
          </Select>
        </Box>

        <Grid container spacing={4}>
          <Grid item>
            <Box
              style={{ display: "flex", alignItems: "center", marginTop: 18 }}
            >
              <Typography style={{ width: 200 }}>Experience:</Typography>
              <Select
                style={{ width: 150 }}
                variant="outlined"
                value={experience}
                onChange={(event) => {
                  setExperience(event.target.value);
                }}
              >
                <MenuItem value="beginner">Beginner</MenuItem>
                <MenuItem value="intermediate">Intermediate</MenuItem>
                <MenuItem value="advanced">Advanced</MenuItem>
                <MenuItem value="expert">Expert</MenuItem>
              </Select>
            </Box>
          </Grid>
          <Grid item>
            <Box
              style={{ display: "flex", alignItems: "center", marginTop: 18 }}
            >
              <Typography style={{ width: 75 }}>Activity Level:</Typography>
              <Select
                style={{ width: 250 }}
                variant="outlined"
                value={activityLevel}
                onChange={(event) => {
                  setActivityLevel(event.target.value);
                }}
              >
                <MenuItem value={ActivityLevel.sedentary}>
                  {ActivityLevelLabel.sedentary}
                </MenuItem>
                <MenuItem value={ActivityLevel.moderate1}>
                  {ActivityLevelLabel.moderate1}
                </MenuItem>
                <MenuItem value={ActivityLevel.moderate2}>
                  {ActivityLevelLabel.moderate2}
                </MenuItem>
                <MenuItem value={ActivityLevel.intense1}>
                  {ActivityLevelLabel.intense1}
                </MenuItem>
                <MenuItem value={ActivityLevel.intense2}>
                  {ActivityLevelLabel.intense2}
                </MenuItem>
                <MenuItem value={ActivityLevel.intense3}>
                  {ActivityLevelLabel.intense3}
                </MenuItem>
              </Select>
            </Box>
          </Grid>
        </Grid>

        <Grid container spacing={4}>
          <Grid item>
            <Box
              style={{ display: "flex", alignItems: "center", marginTop: 18 }}
            >
              <Typography style={{ width: 200 }}>Tier:</Typography>
              <Select
                style={{ width: 150 }}
                variant="outlined"
                value={tier}
                defaultValue={tier}
                onChange={(event) => {
                  setTier(event.target.value);
                }}
              >
                <MenuItem value="tier1">Tier 1</MenuItem>
                <MenuItem value="tier2">Tier 2</MenuItem>
                <MenuItem value="tier3">Tier 3</MenuItem>
              </Select>
            </Box>
          </Grid>
          <Grid item>
            <Box
              style={{ display: "flex", alignItems: "center", marginTop: 18 }}
            >
              <Typography style={{ width: 75 }}>Account Status:</Typography>
              <Select
                style={{ width: 150 }}
                variant="outlined"
                value={status}
                defaultValue={status}
                onChange={(event) => {
                  setStatus(event.target.value);
                }}
              >
                <MenuItem value="active">Active</MenuItem>
                <MenuItem value="disabled">Inactive</MenuItem>
              </Select>
            </Box>
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        {error && (
          <Box style={{ display: "flex", alignItems: "center" }}>
            <Typography style={{ width: 200, color: "red" }}>
              {error}
            </Typography>
          </Box>
        )}

        <Button
          onClick={() => {
            if (onDialogClosed) {
              onDialogClosed(null);
            }
          }}
          color="primary"
        >
          Cancel
        </Button>
        <BlueButton
          onClick={() => {
            const validation = validate();
            if (!validation) {
              if (onDialogClosed) {
                const data = {
                  firstName: firstName,
                  lastName: lastName,
                  email: email,
                  height: height,
                  sex: sex,
                  experience: experience,
                  goal: goal,
                  weight: weight,
                  startingWeight: startingWeight,
                  status: status,
                  activityLevel: activityLevel
                    ? activityLevel
                    : ActivityLevel.sedentary,
                  tier: tier,
                  birthDate: birthDate,
                  language: language,
                  renewDate: renewDate,
                  checkInDay: checkInDay,
                };

                onDialogClosed(data);
              }
            } else {
              setError(validation);
            }
          }}
          color="primary"
        >
          Save
        </BlueButton>
      </DialogActions>
    </Dialog>
  );
};

export default ClientEditDialog;
