import React from "react";
import { Box, Typography, Avatar, Grid, Stack } from "@mui/material";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import moment from "moment";
import { useRecoilValue } from "recoil";
import Colors from "../../../../themes/Colors";
import ConnectCode from "./ConnectCode";
import { uploadUserAvatar } from "../../../../data/UserData";
import { TierTitles } from "../../../../data/ClientData";
import { ActivityLevelLabel } from "../../../../data/Types";
import useClientsLastSeen from "../../../../hooks/useClientsLastSeen";

import difficultyKeysState from "../../../../atoms/difficultyKeysAtom";
import { LanguageCodes } from "../../../../data/Types";
import { DaysOfWeekString } from "../../../../data/GlobalData";
import colors from "../../../../themes/Colors";
import RedBorderButton from "../../../../components/RedBorderButton";

const Name = ({ client, handleChangeAvatar }) => {
  if (client.avatarUrl) {
    return (
      <Box alignItems="center" marginTop={1} marginLeft={1}>
        <Avatar
          src={client.avatarUrl}
          style={{
            height: 120,
            width: 120,
            marginRight: 4,
            marginTop: -5,
          }}
        />
        <Box
          style={{
            borderRadius: 50,
            height: 40,
            width: 40,
            backgroundColor: Colors.lightGray,
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
            position: "absolute",
            marginTop: -30,
            marginLeft: 75,
          }}
        >
          <label>
            <AddAPhotoIcon color="primary" style={{ color: "black" }} />
            <input
              hidden={true}
              type="file"
              accept=".jpg,.jpeg,.png"
              onChange={handleChangeAvatar}
            />
          </label>
        </Box>
      </Box>
    );
  }
  return (
    <Box alignItems="center" marginTop={1} marginLeft={1}>
      <Avatar
        style={{ height: 120, width: 120, marginRight: 4, fontSize: 44 }}
      >{`${client.firstName[0]}${client.lastName[0]}`}</Avatar>

      <Box
        style={{
          borderRadius: 50,
          height: 40,
          width: 40,
          backgroundColor: Colors.lightGray,
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
          position: "absolute",
          marginTop: -28,
          marginLeft: 77,
        }}
      >
        <label>
          <AddAPhotoIcon color="primary" style={{ color: "black" }} />
          <input
            hidden={true}
            type="file"
            accept=".jpg,.jpeg,.png"
            onChange={handleChangeAvatar}
          />
        </label>
      </Box>
    </Box>
  );
};

const ClientDescription = ({ client, orgData, avatarUpdated, editClicked }) => {
  // grid variables for sizing and spacing
  const containerSpacing = 10;
  const col1Length = 30;
  const col1Value =
    String(client.email).length * 7.5 > 120 ? client.email.length * 7.5 : 120;

  const col2Length = 105;
  const col2Value =
    String(client.activityLevel).length * 8 > 110
      ? String(client.activityLevel).length * 8
      : 130;

  const col3Length = 110;
  const col3Value =
    String(client.renewDate).length * 3 > 100
      ? String(client.renewDate).length * 3
      : 100;
  const difficultyKeys = useRecoilValue(difficultyKeysState);

  const { userLastSeen } = useClientsLastSeen({
    organizationId: orgData.id,
    uid: client.uid,
  });

  const languageDictionary = LanguageCodes.reduce((acc, item) => {
    acc[item.code] = item.label;
    return acc;
  }, {});

  const handleChangeAvatar = async (e) => {
    if (e.target.files[0]) {
      const image = e.target.files[0];
      await uploadUserAvatar({
        uid: client.uid,
        file: image,
        progressFunction: () => {
          // Do nothing.
        },
      });

      // Refresh somehow
      if (avatarUpdated) {
        avatarUpdated();
      }
    }
  };

  const handleChangeAvatarCallback = React.useCallback(handleChangeAvatar, [
    avatarUpdated,
  ]);

  function getAge(client) {
    if (client && client.statistics && client.statistics.birthDate) {
      const birthDateMoment = moment(client.statistics.birthDate.toDate());
      const age = moment().diff(birthDateMoment, "years");
      return age;
    }
    return 0;
  }

  return (
    <Box sx={{ width: "100%" }}>
      <Box
        display="flex"
        style={{
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Stack direction={"row"} spacing={1}>
          <Name
            client={client}
            handleChangeAvatar={handleChangeAvatarCallback}
          />
          <Typography
            style={{
              fontSize: 32,
              fontWeight: 700,
              marginTop: 6,
              fontFamily: "Lato",
              fontStyle: "normal",
              paddingLeft: 30,
            }}
          >
            {`${client.firstName} ${client.lastName}`}
          </Typography>
        </Stack>
        <Box
          flexDirection={"column"}
          textAlign={"right"}
          justifyContent={"flex-end"}
          flexShrink={0}
          marginTop={-8.5}
          sx={{ display: "inline-flex", justifyContent: "flex-end" }}
        >
          <RedBorderButton
            onClick={() => {
              if (editClicked) {
                editClicked(client);
              }
            }}
          >
            Edit Client
          </RedBorderButton>
        </Box>
      </Box>

      <Box
        display="flex"
        style={{ marginTop: -80, marginRight: 12 }}
        justifyContent="space-between"
      >
        {/* <Name client={client} handleChangeAvatar={handleChangeAvatarCallback} /> */}
        <Box
          style={{
            marginTop: 20,
            marginLeft: 170,
            marginRight: 12,
            width: "95%",
          }}
        >
          {/* ROW 1 */}
          <Grid spacing={containerSpacing} container>
            <Grid item>
              <Stack direction={"row"} spacing={0}>
                <Box width={col1Length}>
                  <Typography>{"Gender:"}</Typography>
                </Box>
                <Box width={col1Value}>
                  <Typography textAlign={"right"} fontWeight={600}>
                    {client.statistics.sex === "male" ? "Male" : "Female"}
                  </Typography>
                </Box>
              </Stack>
            </Grid>

            <Grid item>
              <Stack direction={"row"} spacing={0}>
                <Box width={col2Length}>
                  <Typography>{"Starting Weight:"}</Typography>
                </Box>
                <Box width={col2Value}>
                  <Typography
                    fontWeight={600}
                    textAlign={"right"}
                    style={
                      client.statistics.startingWeight
                        ? { color: "black" }
                        : { color: colors.primaryRed }
                    }
                  >
                    {client.statistics.startingWeight
                      ? client.statistics.startingWeight
                      : "Not Set"}
                  </Typography>
                </Box>
              </Stack>
            </Grid>

            <Grid item>
              <Stack direction={"row"} spacing={0}>
                <Box width={col3Length}>
                  <Typography>{"Account Status:"}</Typography>
                </Box>
                <Box width={col3Value}>
                  <Typography
                    textAlign={"right"}
                    fontWeight={600}
                    style={
                      client.status
                        ? { color: "#008000" }
                        : { color: colors.primaryRed }
                    }
                  >
                    {client.status
                      ? client.status === "active"
                        ? "Active"
                        : "Inactive"
                      : "Active"}
                  </Typography>
                </Box>
              </Stack>
            </Grid>
          </Grid>

          {/* ROW 2 */}
          <Grid spacing={containerSpacing} container>
            <Grid item>
              <Stack direction={"row"} spacing={0}>
                <Box width={col1Length}>
                  <Typography>{"Height:"}</Typography>
                </Box>
                <Box width={col1Value}>
                  <Typography
                    textAlign={"right"}
                    fontWeight={600}
                    style={
                      client.statistics.height
                        ? { color: "#black" }
                        : { color: colors.primaryRed }
                    }
                  >
                    {client.statistics.height
                      ? client.statistics.height
                      : "Not Set"}
                  </Typography>
                </Box>
              </Stack>
            </Grid>

            <Grid item>
              <Stack direction={"row"} spacing={0}>
                <Box width={col2Length}>
                  <Typography>{"Fitness Goal:"}</Typography>
                </Box>
                <Box width={col2Value}>
                  <Typography
                    textAlign={"right"}
                    fontWeight={600}
                    style={
                      client.statistics.goal
                        ? { color: "black" }
                        : { color: colors.primaryRed }
                    }
                  >
                    {client.statistics.goal
                      ? client.statistics.goal
                      : "Not Set"}
                  </Typography>
                </Box>
              </Stack>
            </Grid>

            <Grid item>
              <Stack direction={"row"} spacing={0}>
                <Box width={col3Length}>
                  <Typography>{"Renew Date:"}</Typography>
                </Box>
                <Box width={col3Value}>
                  <Typography
                    textAlign={"right"}
                    fontWeight={600}
                    style={
                      client.renewDate
                        ? { color: "black" }
                        : { color: colors.primaryRed }
                    }
                  >
                    {client.renewDate
                      ? moment(client.renewDate.toDate()).format("MM/DD/YYYY")
                      : "Not Set"}
                  </Typography>
                </Box>
              </Stack>
            </Grid>
          </Grid>

          {/*ROW 3*/}
          <Grid spacing={containerSpacing} container>
            <Grid item>
              <Stack direction={"row"} spacing={0}>
                <Box width={col1Length}>
                  <Typography>{"Age:"}</Typography>
                </Box>
                <Box width={col1Value}>
                  <Typography fontWeight={600} textAlign={"right"}>
                    {getAge(client)}
                  </Typography>
                </Box>
              </Stack>
            </Grid>

            <Grid item>
              <Stack direction={"row"} spacing={0}>
                <Box width={col2Length}>
                  <Typography>{"Experience:"}</Typography>
                </Box>
                <Box width={col2Value}>
                  <Typography textAlign={"right"} fontWeight={600}>
                    {difficultyKeys[client.statistics.experience]}
                  </Typography>
                </Box>
              </Stack>
            </Grid>
            <Grid item>
              <Stack direction={"row"} spacing={0}>
                <Box width={col3Length}>
                  <Typography>{"Tier:"}</Typography>
                </Box>
                <Box width={col3Value}>
                  <Typography textAlign={"right"} fontWeight={600}>
                    {client.tier ? TierTitles[client.tier] : "Tier 1"}
                  </Typography>
                </Box>
              </Stack>
            </Grid>
          </Grid>

          {/* Row 4 */}
          <Grid spacing={containerSpacing} container>
            <Grid item>
              <Stack direction={"row"} spacing={0}>
                <Box width={col1Length}>
                  <Typography>{"Language:"}</Typography>
                </Box>
                <Box width={col1Value}>
                  <Typography textAlign={"right"} fontWeight={600}>
                    {client.language
                      ? languageDictionary[client.language]
                      : languageDictionary["en"]}
                  </Typography>
                </Box>
              </Stack>
            </Grid>

            <Grid item>
              <Stack direction={"row"} spacing={0}>
                <Box width={col2Length}>
                  <Typography>{"Activity Level:"}</Typography>
                </Box>
                <Box width={col2Value}>
                  <Typography textAlign={"right"} fontWeight={600}>
                    {client.statistics.activityLevel
                      ? ActivityLevelLabel[client.statistics.activityLevel]
                      : "No activity level"}
                  </Typography>
                </Box>
              </Stack>
            </Grid>
            <Grid item>
              <Stack direction={"row"} spacing={0}>
                <Box width={col3Length}>
                  <Typography>{"Check In Day:"}</Typography>
                </Box>
                <Box width={col3Value}>
                  <Typography
                    textAlign={"right"}
                    fontWeight={600}
                    style={
                      client.checkInDay
                        ? { color: "black" }
                        : { color: colors.primaryRed }
                    }
                  >
                    {client.checkInDay
                      ? DaysOfWeekString[client.checkInDay]
                      : "Not Set"}
                  </Typography>
                </Box>
              </Stack>
            </Grid>
          </Grid>
        </Box>
      </Box>

      <ConnectCode
        uid={client.uid}
        connectCode={client.connectCode}
        connectCodeEmailSentOn={client.connectCodeEmailSentOn}
        organizationName={orgData.name}
        organizationId={orgData.id}
      />
      <Box
        textAlign={"right"}
        justifyContent={"flex-end"}
        style={{ marginTop: 12 }}
      >
        <Typography style={{ fontSize: 12 }}>{`uid: ${client.uid} - ${
          userLastSeen ? userLastSeen.appVersion : "N/A"
        }`}</Typography>
      </Box>
    </Box>
  );
};

export default ClientDescription;
