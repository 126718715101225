var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import { consola } from "consola";
import _ from "lodash";
var useProtocols = function () {
    function getExercises(_a) {
        return __awaiter(this, arguments, void 0, function (_b) {
            var exercisesRef, exercisesDocs;
            var organizationId = _b.organizationId, splitId = _b.splitId;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        exercisesRef = firebase
                            .firestore()
                            .collection("organizations")
                            .doc(organizationId)
                            .collection("protocolExercises")
                            .where("splitId", "==", splitId)
                            .orderBy("index", "asc");
                        consola.info("++++++ READ -> useProtocols: getExercises");
                        return [4 /*yield*/, exercisesRef.get()];
                    case 1:
                        exercisesDocs = _c.sent();
                        return [2 /*return*/, exercisesDocs.docs.map(function (doc) { return doc.data(); })];
                }
            });
        });
    }
    function getSplits(_a) {
        return __awaiter(this, arguments, void 0, function (_b) {
            var splitsRef, splitsDocs, splitData, splitExercises;
            var _this = this;
            var organizationId = _b.organizationId, protocolId = _b.protocolId;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        splitsRef = firebase
                            .firestore()
                            .collection("organizations")
                            .doc(organizationId)
                            .collection("protocolSplits")
                            .where("protocolId", "==", protocolId)
                            .orderBy("index", "asc");
                        consola.info("++++++ READ -> useProtocols: getSplits");
                        return [4 /*yield*/, splitsRef.get()];
                    case 1:
                        splitsDocs = _c.sent();
                        splitData = splitsDocs.docs.map(function (doc) { return doc.data(); });
                        return [4 /*yield*/, Promise.all(splitData.map(function (split) { return __awaiter(_this, void 0, void 0, function () {
                                var exercises;
                                return __generator(this, function (_a) {
                                    switch (_a.label) {
                                        case 0: return [4 /*yield*/, getExercises({
                                                organizationId: organizationId,
                                                splitId: split.id,
                                            })];
                                        case 1:
                                            exercises = _a.sent();
                                            return [2 /*return*/, __assign(__assign({}, split), { exercises: exercises })];
                                    }
                                });
                            }); }))];
                    case 2:
                        splitExercises = _c.sent();
                        return [2 /*return*/, splitExercises];
                }
            });
        });
    }
    function getProtocols(_a) {
        return __awaiter(this, arguments, void 0, function (_b) {
            var protocolRef, protocolDocs, protocolData;
            var organizationId = _b.organizationId;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        protocolRef = firebase
                            .firestore()
                            .collection("organizations")
                            .doc(organizationId)
                            .collection("protocols");
                        return [4 /*yield*/, protocolRef.get()];
                    case 1:
                        protocolDocs = _c.sent();
                        consola.info("++++++ READ -> useProtocols: getProtocols", protocolDocs.docs.length);
                        protocolData = protocolDocs.docs.map(function (doc) { return doc.data(); });
                        return [2 /*return*/, protocolData];
                }
            });
        });
    }
    function findProtocol(_a) {
        var protocolId = _a.protocolId, protocols = _a.protocols;
        var protocol = _.find(protocols, { id: protocolId });
        return protocol;
    }
    function getAllProtocols(_a) {
        return __awaiter(this, arguments, void 0, function (_b) {
            var protocolRef, protocolDocs, protocolData, protocolSplits;
            var _this = this;
            var organizationId = _b.organizationId;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        protocolRef = firebase
                            .firestore()
                            .collection("organizations")
                            .doc(organizationId)
                            .collection("protocols");
                        return [4 /*yield*/, protocolRef.get()];
                    case 1:
                        protocolDocs = _c.sent();
                        protocolData = protocolDocs.docs.map(function (doc) { return doc.data(); });
                        consola.info("++++++ READ -> useProtocols: getAllProtocols", protocolData.length);
                        return [4 /*yield*/, Promise.all(protocolData.map(function (protocol) { return __awaiter(_this, void 0, void 0, function () {
                                var splits;
                                return __generator(this, function (_a) {
                                    switch (_a.label) {
                                        case 0: return [4 /*yield*/, getSplits({
                                                organizationId: organizationId,
                                                protocolId: protocol.id,
                                            })];
                                        case 1:
                                            splits = _a.sent();
                                            return [2 /*return*/, __assign(__assign({}, protocol), { splits: splits })];
                                    }
                                });
                            }); }))];
                    case 2:
                        protocolSplits = _c.sent();
                        return [2 /*return*/, protocolSplits];
                }
            });
        });
    }
    function findExercisesBySetType(_a) {
        var setType = _a.setType, protocols = _a.protocols;
        var exercises = [];
        _.forEach(protocols, function (protocol) {
            _.forEach(protocol.splits, function (split) {
                _.forEach(split.exercises, function (exercise) {
                    if (exercise.type === setType) {
                        if (exercise.exercises &&
                            exercise.exercises.length > 0 &&
                            exercise.exercise &&
                            exercise.exercise !== "") {
                            exercises.push(__assign({}, exercise));
                        }
                    }
                });
            });
        });
        return exercises;
    }
    function findExercise(_a) {
        var exerciseId = _a.exerciseId, protocols = _a.protocols;
        var exercises = [];
        var protocolId = "";
        var splitId = "";
        // recursively find any key named exercise that matches the exerciseId
        function find(exerciseId, protocols) {
            _.forEach(protocols, function (value, key, collection) {
                if (key === "protocolId")
                    protocolId = value;
                if (key === "splitId")
                    splitId = value;
                if (key === "exercise" && value === exerciseId) {
                    exercises.push({ key: key, value: value, protocolId: protocolId, splitId: splitId });
                }
                else if (_.isObject(value)) {
                    find(exerciseId, value);
                }
            });
        }
        find(exerciseId, protocols);
        // filter exercises where splitId is empty
        var filtered = _.filter(exercises, function (e) { return e.splitId !== ""; });
        var unique = _.uniqBy(filtered, function (elem) {
            return [elem.protocolId, elem.value].join();
        });
        return unique;
    }
    return {
        getAllProtocols: getAllProtocols,
        getProtocols: getProtocols,
        findExercise: findExercise,
        findProtocol: findProtocol,
        findExercisesBySetType: findExercisesBySetType,
    };
};
export default useProtocols;
