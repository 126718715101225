var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import firebase from "firebase/compat/app";
import "firebase/compat/analytics";
import "firebase/compat/storage";
import "firebase/compat/firestore";
import { getRecoil } from "recoil-nexus";
import { nanoid } from "nanoid";
import _ from "underscore";
import organizationIdState from "../atoms/organizationIdSelector";
import userDataState from "../atoms/userDataAtom";
import { UserType } from "./OrganizationData";
import { consola } from "consola";
export var AppDestination = { messaging: "messaging", dashboard: "dashboard" };
export var GroupType = { alert: "alert", group: "group", questions: "questions", single: "single" };
export function getMessageGroupWithUID(users) {
    var arguments_1 = arguments;
    return __awaiter(this, void 0, void 0, function () { var type, organizationId, groupRef, groups; return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                type = arguments_1.length > 1 && arguments_1[1] !== undefined ? arguments_1[1] : GroupType.single;
                organizationId = getRecoil(organizationIdState);
                groupRef = firebase.firestore().collection("organizations").doc(organizationId).collection("messageGroups").where("users", "array-contains-any", users).where("type", "==", type);
                consola.info("++++++ READ -> MessageData: getMEssageGroupWithUID");
                return [4 /*yield*/, groupRef.get()];
            case 1:
                groups = _a.sent();
                return [2 /*return*/, groups.docs.map(function (d) { return d.data(); })];
        }
    }); });
}
export function sendMessageToGroup(groupId, message) {
    var arguments_2 = arguments;
    return __awaiter(this, void 0, void 0, function () { var appDestination, batch, organizationId, userState, messageId, groupRef, group, messageRef; return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                appDestination = arguments_2.length > 2 && arguments_2[2] !== undefined ? arguments_2[2] : AppDestination.messaging;
                if (!groupId || !message || message.length === 0) {
                    consola.error(new Error("Missing required data in sendMessageToGroup"));
                }
                batch = firebase.firestore().batch();
                organizationId = getRecoil(organizationIdState);
                userState = getRecoil(userDataState);
                messageId = nanoid();
                groupRef = firebase.firestore().doc("organizations/".concat(organizationId, "/messageGroups/").concat(groupId));
                consola.info("++++++ READ -> MessageData: sendMessageToGroup");
                return [4 /*yield*/, groupRef.get()];
            case 1:
                group = _a.sent();
                if (!group.exists) return [3 /*break*/, 3];
                messageRef = firebase.firestore().collection("organizations").doc(organizationId).collection("messageGroups").doc(groupId).collection("messages").doc(messageId);
                consola.info("+++++ WRITE => MessageData: sendMessageToGroup ");
                batch.set(messageRef, { id: messageId, createdOn: new Date(), fromUID: userState.uid, groupId: groupId, fromUser: userState, messageType: "text", processed: false, processedOn: null, viewedBy: [], appDestination: appDestination, message: message }, { merge: true });
                batch.update(groupRef, { lastMessage: message, lastUser: userState, lastDate: new Date() });
                consola.info("+++++ WRITE => MessageData: sendMessageToGroup");
                return [4 /*yield*/, batch.commit()];
            case 2:
                _a.sent();
                _a.label = 3;
            case 3: return [2 /*return*/];
        }
    }); });
}
export function sendMessageToUsersGroup(_ref) {
    return __awaiter(this, void 0, void 0, function () {
        var uid, message, _a, mustBeRegistered, userRef, user, userData, clientRef, client, clientData, override, messageGroups, messageGroup;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    uid = _ref.uid, message = _ref.message, _a = _ref.mustBeRegistered, mustBeRegistered = _a === void 0 ? true : _a;
                    userRef = firebase.firestore().collection("users").doc(uid);
                    consola.info("++++++ READ -> MessageData: sendMessageToUsersGroup");
                    return [4 /*yield*/, userRef.get()];
                case 1:
                    user = _b.sent();
                    if (!user.exists) return [3 /*break*/, 4];
                    userData = user.data();
                    if (!userData) return [3 /*break*/, 4];
                    clientRef = firebase.firestore().collection("organizations").doc(userData.organizationId).collection("clients").doc(uid);
                    return [4 /*yield*/, clientRef.get()];
                case 2:
                    client = _b.sent();
                    if (!client.exists) return [3 /*break*/, 4];
                    clientData = client.data();
                    if (!clientData) return [3 /*break*/, 4];
                    override = process.env.REACT_APP_OVERRIDE_SEND_MESSAGE || false;
                    if (mustBeRegistered && !clientData.authId && !override) {
                        consola.log("User is not registered, not sending message");
                        return [2 /*return*/];
                    }
                    return [4 /*yield*/, getMessageGroupWithUID([uid])];
                case 3:
                    messageGroups = _b.sent();
                    if (messageGroups.length > 0) {
                        messageGroup = messageGroups[0];
                        sendMessageToGroup(messageGroup.id, message);
                    }
                    _b.label = 4;
                case 4: return [2 /*return*/];
            }
        });
    });
}
export function updateMessageViewedBy(_ref2) {
    return __awaiter(this, void 0, void 0, function () { var groupId, messageId, userId, organizationId, messageRef, message, messageValue, viewedBy, viewedByNew, index; return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                groupId = _ref2.groupId, messageId = _ref2.messageId, userId = _ref2.userId;
                organizationId = getRecoil(organizationIdState);
                messageRef = firebase.firestore().collection("organizations").doc(organizationId).collection("messageGroups").doc(groupId).collection("messages").doc(messageId);
                consola.info("++++++ READ -> MessageData: upateMessageViewedBy");
                return [4 /*yield*/, messageRef.get()];
            case 1:
                message = _a.sent();
                if (!(message && message.exists)) return [3 /*break*/, 3];
                messageValue = message.data();
                if (!messageValue) return [3 /*break*/, 3];
                viewedBy = messageValue.viewedBy;
                viewedByNew = [];
                if (viewedBy) {
                    viewedByNew = viewedBy;
                }
                index = _.findIndex(viewedByNew, { uid: userId });
                if (!(index === -1)) return [3 /*break*/, 3];
                viewedByNew.push({ uid: userId, viewedOn: new Date() });
                consola.info("+++++ WRITE => MessageData: updateMessageViewedBy");
                return [4 /*yield*/, messageRef.set({ viewedBy: viewedByNew }, { merge: true })];
            case 2:
                _a.sent();
                _a.label = 3;
            case 3: return [2 /*return*/];
        }
    }); });
}
export function updateMessageGroupUserActivity(uid, messageGroupId, source) {
    return __awaiter(this, void 0, void 0, function () {
        var organizationId, activityRef;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!messageGroupId) {
                        consola.error(new Error("Missing parameter messageGroupId"));
                    }
                    organizationId = getRecoil(organizationIdState);
                    activityRef = firebase.firestore().collection("organizations").doc(organizationId).collection("messageGroupActivity").doc("".concat(messageGroupId, "_").concat(uid));
                    consola.info("+++++ WRITE => MessageData: updateMessageGroupUserActivity");
                    return [4 /*yield*/, activityRef.set({ lastSeenOn: new Date(), uid: uid, messageGroupId: messageGroupId, source: source ? source : "unknown" }, { merge: true })];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    });
}
var MessageData = { getMessagesSubscription: function (_ref3) { return __awaiter(void 0, void 0, void 0, function () {
        var groupId, _a, limit, _b, filter, onMessages, organizationId, messagesRef, filterArray;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    groupId = _ref3.groupId, _a = _ref3.limit, limit = _a === void 0 ? 30 : _a, _b = _ref3.filter, filter = _b === void 0 ? null : _b, onMessages = _ref3.onMessages;
                    if (!groupId) {
                        return [2 /*return*/, null];
                    }
                    organizationId = getRecoil(organizationIdState);
                    if (!filter) {
                        messagesRef = firebase.firestore().collection("organizations").doc(organizationId).collection("messageGroups").doc(groupId).collection("messages").orderBy("createdOn", "desc").limit(limit);
                    }
                    else { // get array of strings from filter
                        filterArray = filter.split(" ");
                        messagesRef = firebase.firestore().collection("organizations").doc(organizationId).collection("messageGroups").doc(groupId).collection("messages").where("keywords", "array-contains-any", filterArray).orderBy("createdOn", "desc").limit(limit);
                    }
                    return [4 /*yield*/, messagesRef.onSnapshot(function (snapshot) {
                            consola.info("++++++ SNAPSHOT -> messageData: getMessagesSubscription");
                            if (snapshot && snapshot.docs && snapshot.docs.length > 0) {
                                var docs = snapshot.docs; // filter docs that do not have the exact filter string
                                if (filter && filter.length > 0) {
                                    var filtered = snapshot.docs.filter(function (doc) {
                                        var docData = doc.data();
                                        if (docData) {
                                            var message = docData.message;
                                            var messageType = docData.messageType; // message contains the filter string
                                            if (messageType === "text") {
                                                if (message && message.toLowerCase().includes(filter.toLowerCase())) {
                                                    return true;
                                                }
                                            }
                                            else {
                                                return true;
                                            }
                                        }
                                        return false;
                                    });
                                    docs = filtered;
                                }
                                onMessages(docs);
                            }
                            else {
                                onMessages([]);
                            }
                        })];
                case 1: return [2 /*return*/, _c.sent()];
            }
        });
    }); }, getMessagesHistorySubscription: function (_ref4) {
        var uid = _ref4.uid, _a = _ref4.limit, limit = _a === void 0 ? 30 : _a, onMessages = _ref4.onMessages;
        var organizationId = getRecoil(organizationIdState);
        var messagesHistoryRef = firebase.firestore().collection("organizations").doc(organizationId).collection("messageHistory").where("users", "array-contains", uid).where("message.fromUID", "!=", uid).orderBy("message.fromUID", "asc").orderBy("createdOn", "desc").limit(limit);
        return messagesHistoryRef.onSnapshot(function (snapshot) {
            consola.info("++++++ SNAPSHOT -> messageData: getMessagesHistorySubscription");
            if (snapshot && snapshot.docs) { // filter out docs that have the current user in the notificationUsers array
                var filtered = snapshot.docs.filter(function (doc) { var docData = doc.data(); if (docData) {
                    var notificationUsers = docData.notificationUsers;
                    if (notificationUsers) {
                        var index = notificationUsers.indexOf(uid);
                        if (index === -1) {
                            return true;
                        }
                    }
                } return false; });
                onMessages(filtered);
            }
        });
    }, markMessageHistorySent: function (_ref5) { return __awaiter(void 0, void 0, void 0, function () {
        var messageId, uid, organizationId, messagesHistoryDocRef, messagesHistoryDoc, messagesHistoryDocData;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    messageId = _ref5.messageId, uid = _ref5.uid;
                    organizationId = getRecoil(organizationIdState);
                    messagesHistoryDocRef = firebase.firestore().collection("organizations").doc(organizationId).collection("messageHistory").doc(messageId);
                    consola.info("++++++ READ -> MessageData: markMessageHistorySent");
                    return [4 /*yield*/, messagesHistoryDocRef.get()];
                case 1:
                    messagesHistoryDoc = _a.sent();
                    if (!messagesHistoryDoc.exists) return [3 /*break*/, 3];
                    messagesHistoryDocData = messagesHistoryDoc.data();
                    if (!messagesHistoryDocData) return [3 /*break*/, 3];
                    consola.info("+++++ WRITE => MessageData: markMessageHistorySent");
                    return [4 /*yield*/, messagesHistoryDocRef.set({ notificationUsers: __spreadArray([uid], messagesHistoryDocData.notificationUsers, true) }, { merge: true })];
                case 2:
                    _a.sent();
                    _a.label = 3;
                case 3: return [2 /*return*/];
            }
        });
    }); }, getMessageSubscriptionForUser: function (toUID, fromUID, onMessage) { return __awaiter(void 0, void 0, void 0, function () { var organizationId, messageRef; return __generator(this, function (_a) {
        organizationId = getRecoil(organizationIdState);
        messageRef = firebase.firestore().collection("organizations").doc(organizationId).collection("messages").where("toUID", "==", toUID);
        if (fromUID) {
            messageRef = messageRef.where("fromUID", "==", fromUID);
        }
        return [2 /*return*/, messageRef.onSnapshot(function (snapshot) { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
                consola.info("++++++ SNAPSHOT -> messageData: getMessageSubscriptionForUser");
                if (onMessage) {
                    onMessage(snapshot.docs);
                }
                return [2 /*return*/];
            }); }); })];
    }); }); }, editMessageGroup: function (groupId, input) { return __awaiter(void 0, void 0, void 0, function () { var organizationId, groupRef, group; return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                organizationId = getRecoil(organizationIdState);
                groupRef = firebase.firestore().collection("organizations").doc(organizationId).collection("messageGroups").doc(groupId);
                consola.info("++++++ READ -> MessageData: editMessageGroup");
                return [4 /*yield*/, groupRef.get()];
            case 1:
                group = _a.sent();
                if (!group.exists) return [3 /*break*/, 3];
                consola.info("+++++ WRITE => MessageData: editMessageGroup");
                return [4 /*yield*/, groupRef.set(input, { merge: true })];
            case 2:
                _a.sent();
                _a.label = 3;
            case 3: return [2 /*return*/];
        }
    }); }); }, addMessageGroup: function (input) { return __awaiter(void 0, void 0, void 0, function () { var groupId, organizationId, groupRef, data; return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                groupId = nanoid();
                organizationId = getRecoil(organizationIdState);
                groupRef = firebase.firestore().collection("organizations").doc(organizationId).collection("messageGroups").doc(groupId);
                data = __assign({ id: groupId }, input);
                consola.info("+++++ WRITE => MessageData: addMessageGroup");
                return [4 /*yield*/, groupRef.set(data, { merge: true })];
            case 1:
                _a.sent();
                return [2 /*return*/, data];
        }
    }); }); }, getMessageGroup: function (userId) {
        var arguments_3 = arguments;
        return __awaiter(this, void 0, void 0, function () {
            var type, order, organizationId, groupRef, groups, groupDataArray;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        type = arguments_3.length > 1 && arguments_3[1] !== undefined ? arguments_3[1] : GroupType.single;
                        order = arguments_3.length > 2 && arguments_3[2] !== undefined ? arguments_3[2] : false;
                        organizationId = getRecoil(organizationIdState);
                        groupRef = firebase.firestore().collection("organizations").doc(organizationId).collection("messageGroups");
                        if (type === "type") {
                            groupRef = groupRef.where("type", "==", type);
                            groupRef = groupRef.orderBy("type");
                        }
                        if (type === GroupType.single) {
                            groupRef = groupRef.where("userId", "==", userId);
                            if (order) {
                                groupRef = groupRef.orderBy("lastDate");
                            }
                        }
                        if (type === UserType.coach) {
                            groupRef = groupRef.orderBy("lastDate", "desc");
                        }
                        if (type === "all") {
                            groupRef = groupRef.orderBy("type");
                        }
                        consola.info("++++++ READ -> MessageData: getMessageGroup");
                        return [4 /*yield*/, groupRef.get()];
                    case 1:
                        groups = _a.sent();
                        groupDataArray = groups.docs.map(function (d) { return __assign(__assign({}, d.data()), { ref: d.ref }); });
                        if (type !== GroupType.single && type !== "type" && type !== "all") {
                            groupDataArray = _.filter(groupDataArray, function (d) { if (d.users) {
                                return d.users.includes(userId);
                            } return true; });
                        }
                        return [2 /*return*/, groupDataArray];
                }
            });
        });
    }, sendMessageToGroup: function (groupId, message) {
        var arguments_4 = arguments;
        return __awaiter(this, void 0, void 0, function () { var appDestination, batch, organizationId, userState, messageId, groupRef, group, messageRef; return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    appDestination = arguments_4.length > 2 && arguments_4[2] !== undefined ? arguments_4[2] : AppDestination.messaging;
                    if (!groupId || !message || message.length === 0) {
                        return [2 /*return*/];
                    }
                    batch = firebase.firestore().batch();
                    organizationId = getRecoil(organizationIdState);
                    userState = getRecoil(userDataState);
                    messageId = nanoid();
                    groupRef = firebase.firestore().doc("organizations/".concat(organizationId, "/messageGroups/").concat(groupId));
                    consola.info("++++++ READ -> MessageData: sendMessageToGroup", groupId, userState.uid);
                    return [4 /*yield*/, groupRef.get()];
                case 1:
                    group = _a.sent();
                    if (!group.exists) return [3 /*break*/, 3];
                    messageRef = firebase.firestore().collection("organizations").doc(organizationId).collection("messageGroups").doc(groupId).collection("messages").doc(messageId);
                    consola.info("+++++ WRITE => MessageData: sendMessageToGroup");
                    batch.set(messageRef, { id: messageId, createdOn: new Date(), fromUID: userState.uid, groupId: groupId, fromUser: userState, messageType: "text", processed: false, processedOn: null, viewedBy: [], appDestination: appDestination, message: message }, { merge: true });
                    batch.update(groupRef, { lastMessage: message, lastUser: userState, lastDate: new Date() });
                    consola.info("+++++ WRITE => MessageData: sendMessageToGroup");
                    return [4 /*yield*/, batch.commit()];
                case 2:
                    _a.sent();
                    _a.label = 3;
                case 3: return [2 /*return*/];
            }
        }); });
    }, sendAudioMessageToGroup: function (groupId, blob, buffer) { return __awaiter(void 0, void 0, void 0, function () { var batch, organizationId, userState, messageId, groupRef, group, messageRef, metadata, file, url, data; return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (!groupId || !blob) {
                    return [2 /*return*/];
                }
                batch = firebase.firestore().batch();
                organizationId = getRecoil(organizationIdState);
                userState = getRecoil(userDataState);
                messageId = nanoid();
                groupRef = firebase.firestore().doc("organizations/".concat(organizationId, "/messageGroups/").concat(groupId));
                consola.info("++++++ READ -> MessageData: sendAudioMessageToGroup");
                return [4 /*yield*/, groupRef.get()];
            case 1:
                group = _a.sent();
                if (!group.exists) return [3 /*break*/, 5];
                messageRef = firebase.firestore().collection("organizations").doc(organizationId).collection("messageGroups").doc(groupId).collection("messages").doc(messageId);
                metadata = { contentType: "audio/mp3" };
                file = new File(buffer, "audio.mp3", { type: blob.type, lastModified: Date.now() });
                return [4 /*yield*/, firebase.storage().ref("/".concat(organizationId, "/messageGroups/").concat(groupId, "/").concat(messageId, ".mp3")).put(file, metadata)];
            case 2:
                _a.sent();
                return [4 /*yield*/, firebase.storage().ref("/".concat(organizationId, "/messageGroups/").concat(groupId, "/").concat(messageId, ".mp3")).getDownloadURL()];
            case 3:
                url = _a.sent();
                data = { id: messageId, createdOn: new Date(), fromUID: userState.uid, groupId: groupId, fromUser: userState, messageType: "audio", processed: false, processedOn: null, viewedBy: [], appDestination: AppDestination.messaging, audioUrl: url, message: "" };
                consola.info("+++++ WRITE => MessageData: sendAudioMessageToGroup");
                batch.set(messageRef, data, { merge: true });
                batch.update(groupRef, { lastMessage: "Audio message uploaded", lastUser: userState, lastDate: new Date() });
                consola.info("+++++ WRITE => sendAudioMessageToGroup");
                return [4 /*yield*/, batch.commit()];
            case 4:
                _a.sent();
                _a.label = 5;
            case 5: return [2 /*return*/];
        }
    }); }); } };
export default MessageData;
