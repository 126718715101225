var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React from "react";
import firebase from "firebase/compat/app";
import "firebase/compat/analytics";
import "firebase/compat/storage";
import "firebase/compat/firestore";
import { getRecoil } from "recoil-nexus";
import Bugsnag from "@bugsnag/js";
import _ from "underscore";
import organizationIdSelector from "../atoms/organizationIdSelector";
import { nanoid } from "nanoid";
import { consola } from "consola";
var useLabels = function () {
    var organizationId = getRecoil(organizationIdSelector);
    var _a = React.useState(), labels = _a[0], setLabels = _a[1];
    var orderLabels = React.useCallback(function (newLabels) {
        return __awaiter(this, void 0, void 0, function () { var batch, index; return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    consola.info("++++++ BATCH -> useLabels: orderLabels");
                    batch = firebase.firestore().batch();
                    index = 0;
                    newLabels.forEach(function (e) { var splitRef = firebase.firestore().collection("organizations").doc(organizationId).collection("labels").doc(e.id); consola.info("+++++ WRITE => useLabels: orderLabels"); batch.set(splitRef, { index: index }, { merge: true }); index += 1; });
                    consola.info("+++++ WRITE => useLabels: orderLabels");
                    return [4 /*yield*/, batch.commit()];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        }); });
    }, [organizationId]);
    var editLabelData = React.useCallback(function (labelData) {
        return __awaiter(this, void 0, void 0, function () {
            var id, ref, e_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        id = nanoid();
                        if (labelData.id) {
                            id = labelData.id;
                        }
                        ref = firebase.firestore().collection("organizations").doc(organizationId).collection("labels").doc(id);
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        consola.info("+++++ WRITE => useLabels: editLabelData");
                        return [4 /*yield*/, ref.set({ id: id, name: labelData.name, color: labelData.color, backgroundColor: labelData.backgroundColor, createdOn: labelData.createdOn, updatedOn: labelData.updatedOn, index: labelData.index }, { merge: true })];
                    case 2:
                        _a.sent();
                        return [3 /*break*/, 4];
                    case 3:
                        e_1 = _a.sent();
                        Bugsnag.notify(new Error("Error saving label data"));
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        });
    }, [organizationId]); // TODO: Deleting a label should activate a cloud function to remove references.
    var deleteLabelData = React.useCallback(function (id) {
        return __awaiter(this, void 0, void 0, function () { var ref, protocolRef, protocols, promises, e_2; return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    ref = firebase.firestore().collection("organizations").doc(organizationId).collection("labels").doc(id);
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 5, , 6]);
                    protocolRef = firebase.firestore().collection("organizations").doc(organizationId).collection("protocols").where("protocolLabels", "array-contains-any", [id]);
                    consola.info("++++++ READ -> useLabels: deleteLabelData");
                    return [4 /*yield*/, protocolRef.get()];
                case 2:
                    protocols = _a.sent();
                    promises = protocols.docs.map(function (protocol) { var data = protocol.data(); if (data.protocolLabels) {
                        var labels_1 = _.clone(data.protocolLabels);
                        var index = labels_1.indexOf(id);
                        if (index !== -1) {
                            labels_1.splice(index, 1);
                            var newIndex_1 = 0;
                            labels_1.forEach(function (item) { item.index = newIndex_1; newIndex_1 += 1; });
                            consola.info("+++++ WRITE => useLabels: deleteLabelData");
                            return protocol.ref.set({ protocolLabels: labels_1 }, { merge: true });
                        }
                    } return null; });
                    return [4 /*yield*/, Promise.all(promises)];
                case 3:
                    _a.sent();
                    return [4 /*yield*/, ref.delete()];
                case 4:
                    _a.sent();
                    return [3 /*break*/, 6];
                case 5:
                    e_2 = _a.sent();
                    Bugsnag.notify(e_2);
                    return [3 /*break*/, 6];
                case 6: return [2 /*return*/];
            }
        }); });
    }, [organizationId]);
    var getLabelById = React.useCallback(function (id) { if (!labels) {
        return null;
    } var value = _.findWhere(labels, { id: id }); return value; }, [labels]);
    var moveLabel = React.useCallback(function (id, direction) { return __awaiter(void 0, void 0, void 0, function () {
        var index, prevLabel, currLabel, prevLabelIndex, index, nextLabel, currLabel, nextLabelIndex;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!labels) {
                        return [2 /*return*/];
                    }
                    if (!(direction === "up")) return [3 /*break*/, 4];
                    index = _.findIndex(labels, { id: id });
                    if (!(index !== -1)) return [3 /*break*/, 3];
                    if (!(index > 0)) return [3 /*break*/, 3];
                    prevLabel = labels[index - 1];
                    currLabel = labels[index];
                    prevLabelIndex = prevLabel.index;
                    prevLabel.index = currLabel.index;
                    currLabel.index = prevLabelIndex; // Save them
                    return [4 /*yield*/, editLabelData(currLabel)];
                case 1:
                    _a.sent();
                    return [4 /*yield*/, editLabelData(prevLabel)];
                case 2:
                    _a.sent();
                    _a.label = 3;
                case 3: return [3 /*break*/, 7];
                case 4:
                    index = _.findIndex(labels, { id: id });
                    if (!(index !== -1)) return [3 /*break*/, 7];
                    if (!(index < labels.length - 1)) return [3 /*break*/, 7];
                    nextLabel = labels[index + 1];
                    currLabel = labels[index];
                    nextLabelIndex = nextLabel.index;
                    nextLabel.index = currLabel.index;
                    currLabel.index = nextLabelIndex; // Save them
                    return [4 /*yield*/, editLabelData(currLabel)];
                case 5:
                    _a.sent();
                    return [4 /*yield*/, editLabelData(nextLabel)];
                case 6:
                    _a.sent();
                    _a.label = 7;
                case 7: return [2 /*return*/];
            }
        });
    }); }, [labels, editLabelData]);
    React.useEffect(function () { var ref = firebase.firestore().collection("organizations").doc(organizationId).collection("labels").orderBy("index"); var subscription = ref.onSnapshot(function (snapshot) { consola.info("++++++ SNAPSHOT -> useLabels"); var data = snapshot.docs.map(function (d) { return d.data(); }); if (data.length === 0) {
        setLabels([]);
    }
    else {
        setLabels(data);
    } }); return function () { subscription(); }; }, [organizationId]);
    return { labels: labels, editLabelData: editLabelData, deleteLabelData: deleteLabelData, moveLabel: moveLabel, getLabelById: getLabelById, orderLabels: orderLabels };
};
export default useLabels;
