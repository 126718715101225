var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React from "react";
import firebase from "firebase/compat/app";
import "firebase/compat/analytics";
import "firebase/compat/storage";
import "firebase/compat/firestore";
import { getRecoil } from "recoil-nexus";
import Bugsnag from "@bugsnag/js";
import _ from "underscore";
import organizationIdSelector from "../atoms/organizationIdSelector";
import { nanoid } from "nanoid";
import { consola } from "consola";
var useProgressPhotos = function () {
    var organizationId = getRecoil(organizationIdSelector);
    var _a = React.useState(), progressPhotos = _a[0], setProgressPhotos = _a[1];
    var saveProgressPhoto = React.useCallback(function (newProgressPhotos) {
        return __awaiter(this, void 0, void 0, function () { var ref, e_1; return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    ref = firebase.firestore().collection("organizations").doc(organizationId);
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    consola.info("+++++ WRITE => useProgressPhotos: saveProgressPhoto");
                    return [4 /*yield*/, ref.set({ progressPhotoConfiguration: newProgressPhotos }, { merge: true })];
                case 2:
                    _a.sent();
                    return [3 /*break*/, 4];
                case 3:
                    e_1 = _a.sent();
                    Bugsnag.notify(new Error("Error saving Progress Photo data"));
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        }); });
    }, [organizationId]);
    var addProgressPhoto = React.useCallback(function (name) {
        return __awaiter(this, void 0, void 0, function () { var progressPhotosCopy; return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    progressPhotosCopy = _.clone(progressPhotos);
                    progressPhotosCopy === null || progressPhotosCopy === void 0 ? void 0 : progressPhotosCopy.push({ id: nanoid(), name: name, index: progressPhotosCopy.length });
                    return [4 /*yield*/, saveProgressPhoto(progressPhotosCopy)];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        }); });
    }, [progressPhotos, saveProgressPhoto]); // TODO: Deleting a photo should activate a cloud function to remove references.
    var deleteProgressPhoto = React.useCallback(function (id) {
        return __awaiter(this, void 0, void 0, function () {
            var progressPhotosCopy, index, i;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!progressPhotos) return [3 /*break*/, 2];
                        progressPhotosCopy = _.clone(progressPhotos);
                        index = _.findIndex(progressPhotosCopy, { id: id });
                        if (!(index !== -1)) return [3 /*break*/, 2];
                        progressPhotosCopy.splice(index, 1); // Reindex
                        for (i = 0; i < progressPhotosCopy.length; i++) {
                            progressPhotosCopy[i].index = i + 1;
                        }
                        return [4 /*yield*/, saveProgressPhoto(progressPhotosCopy)];
                    case 1:
                        _a.sent();
                        _a.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        });
    }, [organizationId, progressPhotos, saveProgressPhoto]);
    var getProgressPhotoById = React.useCallback(function (id) { if (!progressPhotos) {
        return null;
    } var value = _.findWhere(progressPhotos, { id: id }); return value; }, [progressPhotos]);
    var moveProgressPhoto = React.useCallback(function (id, direction) { return __awaiter(void 0, void 0, void 0, function () {
        var index, prevProgressPhoto, currProgressPhoto, prevPhotoIndex, index, nextProgressPhoto, currProgressPhoto, newPhotoIndex;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!progressPhotos) {
                        return [2 /*return*/];
                    }
                    if (!(direction === "up")) return [3 /*break*/, 3];
                    index = _.findIndex(progressPhotos, { id: id });
                    if (!(index !== -1)) return [3 /*break*/, 2];
                    if (!(index > 0)) return [3 /*break*/, 2];
                    prevProgressPhoto = progressPhotos[index - 1];
                    currProgressPhoto = progressPhotos[index];
                    prevPhotoIndex = prevProgressPhoto.index;
                    prevProgressPhoto.index = currProgressPhoto.index;
                    currProgressPhoto.index = prevPhotoIndex; // Save them
                    return [4 /*yield*/, saveProgressPhoto(progressPhotos)];
                case 1:
                    _a.sent();
                    _a.label = 2;
                case 2: return [3 /*break*/, 5];
                case 3:
                    index = _.findIndex(progressPhotos, { id: id });
                    if (!(index !== -1)) return [3 /*break*/, 5];
                    if (!(index < progressPhotos.length - 1)) return [3 /*break*/, 5];
                    nextProgressPhoto = progressPhotos[index + 1];
                    currProgressPhoto = progressPhotos[index];
                    newPhotoIndex = nextProgressPhoto.index;
                    nextProgressPhoto.index = currProgressPhoto.index;
                    currProgressPhoto.index = newPhotoIndex; // Save them
                    return [4 /*yield*/, saveProgressPhoto(progressPhotos)];
                case 4:
                    _a.sent();
                    _a.label = 5;
                case 5: return [2 /*return*/];
            }
        });
    }); }, [progressPhotos, saveProgressPhoto]);
    React.useEffect(function () { var ref = firebase.firestore().collection("organizations").doc(organizationId); var subscription = ref.onSnapshot(function (snapshot) { consola.info("++++++ SNAPSHOT -> useProgressPhotos"); var data = snapshot.data(); if (!data) {
        setProgressPhotos([]);
    }
    else {
        var sorted = _.sortBy(data.progressPhotoConfiguration, "index");
        setProgressPhotos(sorted);
    } }); return function () { subscription(); }; }, [organizationId]);
    return { progressPhotos: progressPhotos, saveProgressPhoto: saveProgressPhoto, deleteProgressPhoto: deleteProgressPhoto, moveProgressPhoto: moveProgressPhoto, getProgressPhotoById: getProgressPhotoById, addProgressPhoto: addProgressPhoto };
};
export default useProgressPhotos;
