import firebase from "firebase/compat/app";
import "firebase/compat/analytics";
import "firebase/compat/storage";
import "firebase/compat/firestore";
import { consola } from "consola";

import moment from "moment";
import { getRecoil } from "recoil-nexus";
import {
  getMessageGroupWithUID,
  sendMessageToGroup,
  AppDestination,
} from "./MessageData";

import organizationIdState from "../atoms/organizationIdSelector";
import userDataState from "../atoms/userDataAtom";

const ReportData = {
  deleteReport: async (uid, reportId) => {
    const ref = firebase.firestore().collection("users").doc(uid);
    consola.info("++++++ READ -> ReportData: userData");
    const userData = await ref.get();

    if (userData.exists) {
      const userDataValues = userData.data();

      const organizationId = userDataValues.organizationId;

      const reportRef = firebase
        .firestore()
        .collection("organizations")
        .doc(organizationId)
        .collection("reports")
        .doc(reportId);
      consola.info("++++++ READ -> ReportData: deleteReport");
      const report = await reportRef.get();
      if (report.exists) {
        await report.ref.delete();
        return true;
      }
    }
    return false;
  },
  getReports: async (uid) => {
    const ref = firebase.firestore().collection("users").doc(uid);
    consola.info("++++++ READ -> ReportData: getReports");
    const userData = await ref.get();

    if (userData.exists) {
      const userDataValues = userData.data();

      const organizationId = userDataValues.organizationId;

      const reportRef = firebase
        .firestore()
        .collection("organizations")
        .doc(organizationId)
        .collection("reports")
        .where("coaches", "array-contains", uid);
      consola.info("++++++ READ -> getReports && userDataExists");
      const reports = await reportRef.get();
      const docs = reports.docs;

      return docs;
    }
    return [];
  },
  getReportsSubscription: async (uid, onChange) => {
    const ref = firebase.firestore().collection("users").doc(uid);
    consola.info("++++++ READ -> reportData: getReportsSubscription");
    const userData = await ref.get();

    if (userData.exists) {
      const userDataValues = userData.data();

      const organizationId = userDataValues.organizationId;
      const reportRef = firebase
        .firestore()
        .collection("organizations")
        .doc(organizationId)
        .collection("reports")
        .where("coaches", "array-contains", uid)
        .orderBy("status", "desc")
        .orderBy("createdOn", "asc");

      return reportRef.onSnapshot((snapshot) => {
        consola.info("++++++ SNAPSHOT -> ReportData: getReportsSubscription");
        if (onChange) {
          onChange(snapshot);
        }
      });
    }
    return null;
  },
  getReportsSubscriptionByStatus: async (uid, status, limit = 30, onChange) => {
    const ref = firebase.firestore().collection("users").doc(uid);
    consola.info("++++++ READ -> ReportData: getReportsSubscriptionByStatus");
    const userData = await ref.get();

    if (userData.exists) {
      const userDataValues = userData.data();

      const organizationId = userDataValues.organizationId;
      let reportRef = firebase
        .firestore()
        .collection("organizations")
        .doc(organizationId)
        .collection("reports")
        .where("coaches", "array-contains", uid)
        .where("status", "==", status);

      if (limit) {
        reportRef = reportRef.limit(limit);
      }
      if (status === "unprocessed") {
        reportRef = reportRef.orderBy("createdOn", "asc");
      } else {
        reportRef = reportRef.orderBy("createdOn", "desc");
      }

      return reportRef.onSnapshot((snapshot) => {
        consola.info(
          "++++++ SNAPSHOT -> ReportData: getReportsSubscriptionByStatus",
        );
        if (onChange) {
          onChange(snapshot.docs.map((d) => d.data()));
        }
      });
    }
    return null;
  },
  processReport: async ({ reportId, status = "processed" }) => {
    const organizationId = getRecoil(organizationIdState);
    const user = getRecoil(userDataState);

    const reportRef = firebase
      .firestore()
      .collection("organizations")
      .doc(organizationId)
      .collection("reports")
      .doc(reportId);
    consola.info("++++++ READ -> ReportData: processReport");
    const reportDoc = await reportRef.get();
    if (reportDoc.exists) {
      const reportValue = reportDoc.data();

      let processorUser = null;
      if (status === "processed") {
        processorUser = {
          uid: user.uid,
          firstName: user.firstName,
          lastName: user.lastName,
        };
      }
      consola.info("+++++ WRITE => ReportData: ProcessReport");
      reportRef.set(
        {
          status: status,
          statusUpdate: new Date(),
          processorUser: processorUser,
        },
        { merge: true },
      );

      if (status === "processed") {
        const messageGroups = await getMessageGroupWithUID([reportValue.uid]);
        if (messageGroups.length > 0) {
          const messageGroup = messageGroups[0];

          const startDateString = moment(
            reportValue.startDate?.toDate(),
          ).format("ll");
          const endDateString = moment(reportValue.endDate?.toDate()).format(
            "ll",
          );

          sendMessageToGroup(
            messageGroup.id,
            `Your coach has processed your report for the period ${startDateString} to ${endDateString}`,
            AppDestination.dashboard,
          );
        }
      }
    }
  },
};

export default ReportData;
